.ImageListItem:hover img,
.ImageListItem:hover .card-body {
  opacity: 0.25;
}

.ImageListItem .card {
  overflow: hidden;
}

.ImageListItem img {
  height: 150px;
  object-fit: cover;
}

.ImageListItem_buttons {
  display: none;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.ImageListItem:hover .ImageListItem_buttons {
  display: flex;
}
