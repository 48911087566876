html,
body,
#root {
  height: 100%;
}

#root {
  min-width: 1240px;
}

.breadcrumb {
  min-height: 48px;
}

.c-pointer {
  cursor: pointer;
}

.ml-25 {
  margin-left: 25%;
}

.mr-25 {
  margin-right: 25%;
}

.o-25 {
  opacity: 0.25;
}

.table {
  table-layout: fixed;
}

.table-hover td {
  cursor: pointer;
}

.w-30px {
  width: 30px;
}

.w-60px {
  width: 60px;
}

.w-80px {
  width: 80px;
}

.w-120px {
  width: 120px;
}

.w-140px {
  width: 140px;
}

.w-200px {
  width: 200px;
}

.ws-pre-line {
  white-space: pre-line;
}
