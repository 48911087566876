.List_button {
  background-color: transparent;
  border: none;
  color: #dee2e6;
}

.List_button:focus,
.List_button:active {
  outline: none;
}

.List .table-primary .List_button {
  color: #afb3b7;
}

.List .table-success .List_button {
  color: #afb3b7;
}

.List .table-danger .List_button {
  color: #afb3b7;
}

.List_button:hover {
  color: #212529 !important;
}

.List_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
