.PageFooter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
}

.PageFooter > button + button {
  margin-left: 0.75em;
}
