.ImageList_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -25px;
}

.ImageList_item {
  height: 150px;
  margin-bottom: 25px;
  margin-right: 25px;
  width: 202px;
}

.ImageList_input {
  align-items: center;
  cursor: pointer;
  color: #a7b1c2;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
}

.ImageList_input:hover {
  opacity: 0.5;
}

.ImageList_spinner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
